import { grey } from "../../../theme/colors";
import { Tooltip, Typography } from "@mui/material";

type Props = {
  status: "ON" | "OFF" | "SB" | "DR";
  duration: string;
};
const Totals: React.FC<Props> = ({ status, duration }) => {
  return (
    <Tooltip title={duration.length > 5 ? duration : ""} arrow placement="top">
      <Typography
        component="span"
        textAlign="center"
        display={"inline-block"}
        sx={{ verticalAlign: "middle" }}
        mr={2}
      >
        <Typography fontWeight={"medium"}>{status}</Typography>
        <Typography color={grey[1000]}>{duration.slice(0, 5)}</Typography>
      </Typography>
    </Tooltip>
  );
};

export default Totals;
