import { grey, red } from "../../../theme/colors";
import { StatusMode } from "../../../types/statusCard";
import { LogChart, LogChartType } from "./Chart";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { ReactNode } from "react";

export const extendedModes = [
  "INTERMEDIATE",
  "ENGINE_POWER_UP",
  "ENGINE_SHUT_DOWN",
  "LOGIN",
  "LOGOUT",
  "CERTIFICATION",
];

export type ExtendedStatusMode =
  | StatusMode
  | "INTERMEDIATE"
  | "ENGINE_POWER_UP"
  | "ENGINE_SHUT_DOWN"
  | "LOGIN"
  | "LOGOUT"
  | "CERTIFICATION";

export type Totals = {
  [T in StatusMode]: number;
};

const tzChangeText = "At 02:00 Time zone was changed to";
const wchText =
  "According to USA Time zone change from DST to STD, all today's logs before 02:00 am were moved back for 1 hour! (-1h)";
const schText =
  "According to USA Time zone change from STD to DST, all today's logs before 02:00 am were moved forward for 1 hour! (+1h)";

const LogEvents: React.FC<
  LogChartType & {
    child?: ReactNode;
    hasData?: boolean;
    sch: boolean;
    wch: boolean;
    setIsMouseOnChart: Function;
  }
> = ({ child, hasData, setIsMouseOnChart, sch, wch, ...props }) => {
  return (
    <>
      <Accordion defaultExpanded style={{ marginTop: 0 }}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ margin: 0, paddingLeft: 0 }}
        >
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography variant="h3" color={grey[600]} fontWeight={500}>
              Log events
            </Typography>
            {(sch || wch) && (
              <Tooltip title={wch ? wchText : schText}>
                <Typography variant="h3" color={red} fontWeight={500}>
                  {wch && `${tzChangeText} STD (-1h)`}
                  {sch && `${tzChangeText} DST (+1h)`}
                </Typography>
              </Tooltip>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            sx={{ backgroundColor: "#F8F8F8" }}
            alignItems="center"
            pt="20px"
            pb="10px"
            height={hasData ? "405px" : ""}
          >
            <Grid
              item
              onMouseEnter={() => setIsMouseOnChart(true)}
              onMouseLeave={() => setIsMouseOnChart(false)}
            >
              <LogChart {...props} />
            </Grid>
            <Grid item>{child}</Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LogEvents;
