import ChartDay from "../../../../assets/images/chartday.svg";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { getTimeZone } from "../../../../redux/companies/selectors";
import { setDraft, unsetDrafts } from "../../../../redux/draft/slice";
import { setSelectedRect } from "../../../../redux/simple/slice";
import { RootState } from "../../../../redux/store";
import { LogDetailRows } from "../../Info";
import { ExtendedStatusMode, Totals } from "../LogEvents";
import TT from "../Totals";
import { hhMMSS2Seconds, toHHMM, toHHMMss } from "../utils";
import { Events } from "./Events";
import { Line } from "./Line";
import { Rect } from "./Rect";
import Resizable from "./Resizable";
import { Shift, ShiftType } from "./Shift";
import { Vertical } from "./Vertical";
import {
  getBoundsUtil,
  getDTs,
  getDateFromSeconds,
  getLimit,
  getStatusMode,
  isEditable,
  isInStatusMode,
} from "./utils";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";

dayjs.extend(utc);
dayjs.extend(tz);

export type Row = {
  id: number;
  start: string;
  end?: string;
  duration: string;
  status: ExtendedStatusMode;
  type: string;
  dot_sent: boolean;
};

export type LogChartType = {
  rows?: Array<Row>;
  shifts?: Array<ShiftType>;
  totals?: Totals;
  totalDuration: number;
  highlightedRowId: number;
  date: string;
  hideDetails?: boolean;
  selectedLogs: Array<number>;
  allLogs: LogDetailRows;
  setHighlightedRowId: Dispatch<SetStateAction<number>>;
};

export type Bounds = {
  start: number;
  end: number;
  localStart: number;
  localEnd: number;
};

export const MINIMUM_STATUS_DURATION_IN_MINUTES = 10;

export const LogChart: React.FC<LogChartType> = ({
  rows = [],
  shifts = [],
  totals,
  totalDuration,
  highlightedRowId,
  date,
  setHighlightedRowId: sethighlightedRowId,
  hideDetails,
  selectedLogs,
  allLogs,
}) => {
  const timezone = useAppSelector(getTimeZone);
  const [bounds, setBounds] = useState<Bounds>();
  const drafts = useAppSelector((state: RootState) => state.drafts);
  const [totalDurs, setTotalDurs] = useState<{
    ON?: string;
    OFF?: string;
    SB?: string;
    DR?: string;
  }>({});

  const activityRows = useMemo(
    () => rows.filter((log) => log.type === "ACTIVITY"),
    [rows]
  );
  const eventRows = useMemo(
    () => rows.filter((log) => log.type === "EVENT"),
    [rows]
  );

  const selectedRect = useAppSelector(
    (state: RootState) => state.simple.selectedRect
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hideDetails) {
      dispatch(setSelectedRect(-1));
      dispatch(unsetDrafts());
    }
  }, [date, hideDetails, dispatch]);

  useEffect(() => {
    if (selectedLogs.length > 0) {
      let [DR, SB, OFF, ON] = [0, 0, 0, 0];
      const logs = allLogs.filter((log) =>
        selectedLogs.includes(log.originalId)
      );

      for (const log of logs) {
        if (log.status === "DRIVING" || log.status === "INTERMEDIATE") {
          DR += hhMMSS2Seconds(log.duration);
        } else if (log.status === "ON_DUTY" || log.status === "YARD_MOVE") {
          ON += hhMMSS2Seconds(log.duration);
        } else if (log.status === "OFF_DUTY") {
          OFF += hhMMSS2Seconds(log.duration);
        } else if (log.status === "SLEEPER") {
          SB += hhMMSS2Seconds(log.duration);
        }
      }
      setTotalDurs({
        OFF: toHHMM(OFF / 60),
        ON: toHHMM(ON / 60),
        SB: toHHMM(SB / 60),
        DR: toHHMM(DR / 60),
      });
    } else {
      setTotalDurs({ DR: "00:00", ON: "00:00", OFF: "00:00", SB: "00:00" });
    }
  }, [selectedLogs, allLogs]);

  const renderChart = (row: Row, index: number, isLast = false) => {
    const draft = drafts.drafts.find((draft) => draft.id === row.id);

    const end =
      isLast && dayjs(row.end).isAfter(row.start, "days")
        ? new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString()
        : row.end || "";

    const endDT = getDTs(new Date(end), draft?.end);
    const startDT = getDTs(new Date(row.start), draft?.start);

    return (
      <React.Fragment key={index}>
        <Line
          startDT={startDT}
          endDT={endDT}
          mode={getStatusMode(row.status)}
        />
        {!isLast && (
          <Vertical
            at={endDT}
            currentMode={getStatusMode(row.status)}
            nextMode={getStatusMode(activityRows[index + 1]?.status)}
          />
        )}
        <Rect
          id={row.id}
          startDT={startDT}
          endDT={endDT}
          row={row}
          disabled={hideDetails || row.dot_sent}
          highlightedRowId={highlightedRowId}
          sethighlightedRowId={sethighlightedRowId}
        />
      </React.Fragment>
    );
  };

  const getBounds = useCallback(
    (id: number) => {
      return getBoundsUtil(activityRows, drafts, date, id);
    },
    [date, activityRows, drafts]
  );

  useEffect(() => {
    if (selectedRect > -1 && activityRows.length > 0) {
      setBounds(getBounds(selectedRect));
    }
  }, [selectedRect, activityRows, getBounds]);

  const handleSlider = (values: number[]) => {
    const draft = drafts.drafts.find((draft) => draft.id === selectedRect);
    const rowIndex = activityRows.findIndex((row) => row.id === selectedRect);

    let start = getDateFromSeconds(
      values[0],
      draft?.start || activityRows[rowIndex]?.start
    );

    const lowerLimit = getLimit(activityRows, date, selectedRect, -1);
    const upperLimit = getLimit(activityRows, date, selectedRect, 1);

    const upperDiff = start.diff(upperLimit, "minutes");
    const lowerDiff = start.diff(lowerLimit, "minutes");

    if (upperDiff > -MINIMUM_STATUS_DURATION_IN_MINUTES) {
      start = dayjs(upperLimit).subtract(
        MINIMUM_STATUS_DURATION_IN_MINUTES,
        "minute"
      );
    }

    if (lowerDiff < MINIMUM_STATUS_DURATION_IN_MINUTES) {
      start = dayjs(lowerLimit).add(
        MINIMUM_STATUS_DURATION_IN_MINUTES,
        "minute"
      );
    }

    dispatch(
      setDraft({
        id: selectedRect,
        start: start.format("MM/DD/YYYY hh:mm:ss A"),
        has_been_updated: true,
      })
    );
  };

  const editable = isEditable(activityRows, date, selectedRect);

  return (
    <Box className="ChartWrapper" paddingX={hideDetails ? "0 !important" : ""}>
      <Box display="flex">
        <Box className="NewChart">
          <img src={ChartDay} alt="img" className="NewChart_img" />
          <Box className="statusWrapper">
            {selectedRect > -1 && bounds && editable && (
              <Resizable
                editable={editable}
                bounds={bounds}
                onChange={handleSlider}
                disabled={hideDetails}
                lowerLimit={getLimit(activityRows, date, selectedRect, -1)}
              />
            )}

            {activityRows.map((row, index) => {
              if (dayjs(row.end).isBefore(date, "date")) {
                return <React.Fragment key={index} />;
              }

              const isPast = dayjs(row.start).isBefore(date, "date");
              const isSame = dayjs(row.start).isSame(date, "date");

              const isCurrent = Boolean(
                dayjs().tz(timezone).format("MM/DD/YYYY").match(date)
              );

              const isFuture = dayjs()
                .tz(timezone)
                .isBefore(dayjs(date).endOf("day"), "date");

              const endTime = new Date(
                dayjs().tz(timezone).format()
              ).toLocaleString("en-US", { timeZone: timezone });

              const isLast =
                activityRows[index + 1] &&
                dayjs(activityRows[index + 1].start).isAfter(date, "date");

              const endOfTheDay = new Date(
                new Date(date).setHours(23, 59, 59, 999)
              ).toISOString();

              if (dayjs(row.start).isAfter(date, "date") || isFuture) {
                return <React.Fragment key={index} />;
              }

              if (isPast) {
                const getEndTime = () => {
                  if (isLast) {
                    return endOfTheDay;
                  }

                  if (
                    activityRows[index + 1] &&
                    dayjs(activityRows[index + 1].start).isSame(date, "date")
                  ) {
                    return activityRows[index + 1].start;
                  }

                  if (row.end === "-") {
                    return isCurrent ? endTime : endOfTheDay;
                  }

                  if (dayjs(row.end).isAfter(date, "date")) {
                    return endOfTheDay;
                  }

                  return row.end;
                };

                return renderChart(
                  {
                    ...row,
                    start: new Date(
                      new Date(date).setHours(0, 0, 0, 0)
                    ).toISOString(),
                    end: getEndTime(),
                  },
                  index,
                  isLast
                );
              }

              if (activityRows[index + 1] && !isLast) {
                return renderChart(
                  {
                    ...row,
                    end: activityRows[index + 1].start,
                  },
                  index
                );
              } else if (isLast) {
                return renderChart(
                  {
                    ...row,
                    end: isCurrent ? endTime : endOfTheDay,
                  },
                  index,
                  true
                );
              }

              if (
                isSame &&
                (row.duration === "N/A" || !activityRows[index + 1])
              ) {
                return renderChart(
                  {
                    ...row,
                    end: isCurrent ? endTime : endOfTheDay,
                  },
                  index
                );
              }

              return (
                <React.Fragment key={index}>
                  {isInStatusMode(row.status) &&
                    renderChart(row, index, isLast)}
                </React.Fragment>
              );
            })}
            {shifts.map((shift, index) => (
              <Shift {...shift} key={index} />
            ))}
            {!hideDetails && eventRows.length > 0 && (
              <Events
                rows={eventRows.filter((row) =>
                  dayjs(row.start).isSame(date, "date")
                )}
                sethighlightedRowId={sethighlightedRowId}
              />
            )}
          </Box>
        </Box>
        {!hideDetails && (
          <Box className="DurationWrapper">
            <Box className="Duration">
              <Typography component="span" fontSize="13px" className="D_item ">
                {toHHMMss(totals?.OFF_DUTY)}
              </Typography>
              <Typography component="span" fontSize="13px" className="D_item ">
                {toHHMMss(totals?.SLEEPER)}
              </Typography>
              <Typography component="span" fontSize="13px" className="D_item ">
                {toHHMMss(totals?.DRIVING)}
              </Typography>
              <Typography component="span" fontSize="13px" className="D_item ">
                {toHHMMss(totals?.ON_DUTY)}
              </Typography>
              <Typography component="span" fontSize="13px" className="D_total">
                Total: {toHHMMss(totalDuration)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      {!hideDetails && (
        <Box className="ttt" pt={2}>
          <TT status="OFF" duration={totalDurs.OFF || "-"} />
          <TT status="SB" duration={totalDurs.SB || "-"} />
          <TT status="DR" duration={totalDurs.DR || "-"} />
          <TT status="ON" duration={totalDurs.ON || "-"} />
        </Box>
      )}
    </Box>
  );
};
