// import EyeOffIcon from "../../assets/icons/eyeIcon.svg";
// import EyeOnIcon from "../../assets/icons/eyeOnIcon.svg";
import useAuth from "../../hooks/useAuth";
import { blue, white } from "../../theme/colors";
import { loginValidationSchema } from "../../utils/validationSchemas";
import styled from "@emotion/styled";
import { ButtonProps, OutlinedInputProps } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
// import React, { useState } from "react";
import { toast } from "react-toastify";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: blue[700],
  },
}));

const AuthTextField = styled((props: any) => (
  <TextField
    InputProps={
      {
        disableUnderline: true,
      } as Partial<OutlinedInputProps>
    }
    inputProps={{ style: { WebkitBoxShadow: white } }}
    InputLabelProps={{
      shrink: true,
      sx: {
        color: (theme) => theme.palette.grey[400],
        fontWeight: 400,
        "&.Mui-focused": {
          color: (theme) => theme.palette.grey[400],
          fontWeight: 400,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    input: {
      "&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px #f8f8f8 inset",
      },
    },
    border: `${theme.palette.grey[50]} 1px solid`,
    overflow: "hidden",
    borderRadius: "8px",
    backgroundColor: white,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: white,
    },
    "&.Mui-focused": {
      backgroundColor: white,
    },
  },
}));

function Login() {
  const { signIn } = useAuth();
  // const [showPassword, setShowPassword] = useState(false);

  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleMouseDownPassword = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   event.preventDefault();
  // };

  return (
    <Box
      component={Paper}
      sx={{
        padding: "40px 56px 56px 56px",
        minWidth: "462px",
        minHeight: "454px",
        bgcolor: "#E6EFF7",
        boxShadow: "0px 8px 16px rgba(67, 66, 66, 0.15)",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography
          sx={{
            fontWeight: 300,
            fontSize: "1rem",
            color: (props) => props.palette.grey[600],
          }}
        >
          External Management Dashboard
        </Typography>
      </Stack>
      <Typography variant="h1" textAlign="left" mt={10} mb={5}>
        Log in
      </Typography>
      <Formik
        initialValues={{
          username: "",
          password: "",
          submit: false,
        }}
        validationSchema={loginValidationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signIn(values.username, values.password);
            toast.success("Welcome");
          } catch (error: any) {
            const message =
              error?.response?.data.detail || "Something went wrong";
            toast.error(
              `Login failed: ${
                values.username === "" || values.password === ""
                  ? "Please fill in the required fields"
                  : message
              }`
            );
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <AuthTextField
              value={values.username}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              sx={{ mb: 6 }}
              label="Username"
              id="username"
              variant="filled"
            />
            <AuthTextField
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              fullWidth
              label="Password"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              // type={showPassword ? "text" : "password"}
              type="password"
              variant="filled"
              sx={{ marginBottom: "40px" }}
              // InputProps={{
              //   disableUnderline: true,
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton
              //         aria-label="toggle password visibility"
              //         onClick={handleClickShowPassword}
              //         onMouseDown={handleMouseDownPassword}
              //       >
              //         {showPassword ? (
              //           <img src={EyeOffIcon} alt="icon" />
              //         ) : (
              //           <img src={EyeOnIcon} alt="icon" />
              //         )}
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
            />
            <ColorButton
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
            >
              {isSubmitting ? <CircularProgress /> : "Log in"}
            </ColorButton>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default Login;
