import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { getUserInfo } from "../redux/auth/selectors";
import {
  handleInitialize,
  handleSignIn,
  handleSignout,
} from "../redux/auth/thunks";
import { fetchCompanies } from "../redux/companies/thunks";
import { JWTContextType } from "../types/auth";
import { createContext, ReactNode, useEffect } from "react";

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(fetchCompanies());
  }, [userInfo, dispatch]);

  useEffect(() => {
    dispatch(handleInitialize());
  }, [dispatch]);

  const signIn = (username: string, password: string) =>
    dispatch(handleSignIn(username, password));

  const signOut = async () => {
    dispatch(handleSignout());
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
