import { useAppSelector } from "../../hooks/reduxHooks";
import {
  getIsAuthenticated,
  getIsInitialized,
} from "../../redux/auth/selectors";
import React from "react";
import { Navigate } from "react-router-dom";

// For routes that can only be accessed by unauthenticated users
const GuestGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isInitialized = useAppSelector(getIsInitialized);
  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
