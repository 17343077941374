import timezones from "../../constants/timezones";
import { getUserInfo } from "../auth/selectors";
import { RootState } from "./../store";
import { createSelector } from "@reduxjs/toolkit";

export const getSelectedCompany = (state: RootState): number | null =>
  state.companies.selectedId;

export const getCompanies = (state: RootState) => state.companies.companiesList;

export const getCompaniesLsit = createSelector(
  [getCompanies, getUserInfo],
  (list, info) => {
    const excepted_companies = info?.excepted_companies ?? [];
    return list.filter((l) => !excepted_companies.includes(l.id));
  }
);

export const getTimeZone = createSelector(
  [getSelectedCompany, getCompanies],
  (id, list) => list.find((c) => c.id === id)?.timezone
);

export const getTimeZoneShortHand = createSelector(
  [getSelectedCompany, getTimeZone],
  (id, tz) => {
    if (tz) return timezones.find((t) => t.timezone === tz)?.value;
  }
);
