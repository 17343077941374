import { CompanyShort } from "../../types/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompaniesState {
  companiesList: CompanyShort[];
  selectedId: number | null;
}

const initialState: CompaniesState = {
  companiesList: [],
  selectedId: null,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setSelected: (state, action: PayloadAction<number | null>) => {
      state.selectedId = action.payload;
    },
    setCompaniesList: (state, action: PayloadAction<CompanyShort[]>) => {
      state.companiesList = action.payload;
    },
  },
});

export const { setSelected, setCompaniesList } = companiesSlice.actions;

export default companiesSlice.reducer;
