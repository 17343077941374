import { Box, Typography } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import * as React from "react";

const Loader: React.FC<{
  page?: boolean;
  progressProps?: CircularProgressProps;
  message?: string;
}> = ({ page = false, message, ...progressProps }) => {
  return page ? (
    <CircularProgress
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backdropFilter: "blur(2px)",
      }}
      {...progressProps}
    />
  ) : (
    <Box
      flexDirection="column"
      gap={4}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(2px)",
      }}
    >
      <Typography variant="h4">{message}</Typography>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default Loader;
