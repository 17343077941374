import createMuiTheme from "@mui/material/styles/createTheme";

import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import typography from "./typography";
import variants from "./variants";

const createTheme = () => {
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      shadows: shadows,
      palette: variants.palette,
    },
    {
      name: variants.name,
      header: variants.header,
      sidebar: variants.sidebar,
    }
  );
};

export default createTheme;
