import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

export function setDefaultTimezone(timezone?: string): void {
  if (timezone) {
    sessionStorage.setItem("timezone", timezone);
  }

  const savedTz = getTimezone();

  dayjs.tz.setDefault(timezone || savedTz);
}

export function getTimezone() {
  return sessionStorage.getItem("timezone") || undefined;
}

type Timezone = {
  label: string;
  value: string;
  timezone: string;
};

const timezones: Timezone[] = [
  {
    label: "Atlantic Standard Time (AST)",
    value: "AST",
    timezone: "America/Glace_Bay",
  },
  {
    label: "Eastern Standard Time (EST)",
    value: "EST",
    timezone: "America/New_York",
  },
  {
    label: "Central Standard Time (CST)",
    value: "CST",
    timezone: "America/Chicago",
  },
  {
    label: "Mountain Standard Time (MST)",
    value: "MST",
    timezone: "America/Denver",
  },
  {
    label: "Pacific Standard Time (PST)",
    value: "PST",
    timezone: "America/Los_Angeles",
  },
  {
    label: "Alaskan Standard Time (AKST)",
    value: "AKST",
    timezone: "America/Anchorage",
  },
  {
    label: "Hawaii-Aleutian Standard Time (HST)",
    value: "HST",
    timezone: "US/Aleutian",
  },
  {
    label: "Samoa standard time (UTC-11)",
    value: "SST",
    timezone: "Pacific/Midway",
  },
  {
    label: "Chamorro Standard Time (UTC+10)",
    value: "CHST",
    timezone: "Pacific/Guam",
  },
];

export default timezones;
