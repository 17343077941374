import baseUrl from "../constants/api";
import { PageOptions } from "../types/helpers";
import axiosInstance from "../utils/jwt";
import { exportExcel } from "../utils/utils";

const endPoint = `${baseUrl.https}companies`;

interface GetCompanyProps extends PageOptions {
  name?: string | undefined;
}

const getCompanies = (params: GetCompanyProps) => {
  return axiosInstance.get(`${endPoint}`, { params });
};

const createCompany = (body: any) => axiosInstance.post(`${endPoint}`, body);

const deleteCompany = (id: number | string) => {
  return axiosInstance.patch(`${endPoint}/${id}`);
};

const getCompanyById = (id: number | string) =>
  axiosInstance.get(`${endPoint}/${id}`);

const editCompanyById = (data: unknown, id: number | string) =>
  axiosInstance.put(`${endPoint}/${id}`, data);

const downloadXLSX = async () => {
  exportExcel(
    `${endPoint}/download/list`,
    undefined,
    "companies.xlsx",
    axiosInstance.defaults.headers.common
  );
};

const companiesApi = {
  getCompanies,
  deleteCompany,
  getCompanyById,
  editCompanyById,
  createCompany,
  downloadXLSX,
};
export default companiesApi;
