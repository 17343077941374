import companiesApi from "../../services/companies";
import { AppThunk } from "../store";
import { setCompaniesList, setSelected } from "./slice";

export const fetchCompanies = (): AppThunk => async (dispatch, getState) => {
  const { data } = await companiesApi.getCompanies({ size: 100, page: 1 });
  if (data?.items) {
    dispatch(setCompaniesList(data.items));
    const selectedCompany = Number(
      window.localStorage.getItem("selectedCompany")
    );
    const isSelectedExistInList = data.items.find(
      (c: any) => c.id === selectedCompany
    );

    if (!isSelectedExistInList) {
      const newId = data.items[0]?.id || 0;
      window.localStorage.setItem("selectedCompany", newId.toString());
      dispatch(setSelected(newId));
    } else dispatch(setSelected(selectedCompany));
  }
};

export const changeSelectedCompany =
  (company_id: number): AppThunk =>
  async (dispatch, getState) => {
    sessionStorage.removeItem("date");
    window.localStorage.setItem("selectedCompany", company_id.toString());
    dispatch(setSelected(company_id));
  };
