import { ReactComponent as EventsIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as InspectonIcon } from "../../assets/icons/inspecton.svg";
import { SidebarItemsType } from "../../types/sidebar";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import FormatListBulletedOutlined from "@mui/icons-material/FormatListBulletedOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

const pagesSection1 = [
  {
    href: "/logs",
    icon: FormatListBulletedOutlined,
    title: "Logs",
  },
  {
    href: "/bulk",
    icon: AutoAwesomeMotionOutlinedIcon,
    title: "Bulk logs",
  },
  {
    href: "/checker",
    icon: InfoOutlinedIcon,
    title: "Monitoring",
  },
  {
    href: "/errors",
    icon: WarningAmberOutlined,
    title: "Errors",
  },
  {
    href: "/dots",
    icon: InspectonIcon,
    title: "DOT Inspections",
  },
  {
    href: "/events",
    icon: EventsIcon,
    title: "Events",
  },
] as unknown as SidebarItemsType[];

const pagesSection3 = [
  {
    href: "/admin",
    title: "Administrator",
  },
  {
    href: "/users",
    title: "Users",
  },
  {
    href: "/security",
    title: "Security",
  },
  {
    href: "https://help.1776eld.com/",
    title: "Knowledge Base",
  },
] as unknown as SidebarItemsType[];

const navItems = [
  {
    title: "",
    divider: true,
    pages: pagesSection1,
  },
  {
    title: "",
    divider: false,
    pages: pagesSection3,
  },
];

export default navItems;
