import { AbilityProvider } from "./contexts/AbilityContext";
import { AuthProvider } from "./contexts/JWTContext";
import ErrorBoundary from "./layout/ErrorBoundary";
import { store } from "./redux/store";
import routes from "./routes";
import createTheme from "./theme";
import createEmotionCache from "./utils/createEmotionCache";
import { CacheProvider } from "@emotion/react";
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  return (
    <CacheProvider value={emotionCache}>
      <ToastContainer />
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Management dashboard"
          defaultTitle="Management dashboard"
        />
        <Provider store={store}>
          <MuiThemeProvider theme={createTheme()}>
            <AuthProvider>
              <ErrorBoundary>
                <AbilityProvider>{content}</AbilityProvider>
              </ErrorBoundary>
            </AuthProvider>
          </MuiThemeProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
