import authApi from "../../services/auth";
import usersApi from "../../services/users";
import { isValidToken, setSession } from "../../utils/jwt";
import { AppThunk } from "./../store";
import { initializeAuth, signIn, signOut, updateUserInfo } from "./slice";

export const setUserInfo = (): AppThunk => async (dispatch, getState) => {
  try {
    const { data } = await usersApi.getUserAboutMe();
    dispatch(updateUserInfo(data));
  } catch (err) {
    console.error(err);
  }
};

export const handleInitialize = (): AppThunk => async (dispatch, getState) => {
  const store = getState();
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const refreshToken = window.localStorage.getItem("refreshToken");
    if (accessToken && refreshToken && isValidToken(refreshToken)) {
      setSession({ accessToken, refreshToken });
      dispatch(initializeAuth(true));
      if (!store.auth.user) {
        dispatch(setUserInfo());
      }
    } else dispatch(initializeAuth(false));
  } catch (err) {
    console.error(err);
    dispatch(initializeAuth(false));
  }
};

export const handleSignIn =
  (username: string, password: string): AppThunk =>
  async (dispatch, getState) => {
    const { data } = await authApi.login(username, password);
    const { access_token, refresh_token } = data;
    setSession({ accessToken: access_token, refreshToken: refresh_token });
    dispatch(setUserInfo());
    dispatch(signIn());
  };

export const handleSignout = (): AppThunk => async (dispatch, getState) => {
  setSession({});
  dispatch(signOut());
};
