import { Bounds, MINIMUM_STATUS_DURATION_IN_MINUTES } from ".";
import { Editable, getCurrentSeconds } from "./utils";
import SwipeIcon from "@mui/icons-material/Swipe";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Range } from "react-range";

type ResizableProps = {
  bounds: Bounds;
  editable: Editable;
  lowerLimit: string | Dayjs;
  disabled?: boolean;
  onChange: (values: number[]) => void;
};

export default function Resizable({
  bounds: { start, end, localStart, localEnd },
  editable,
  lowerLimit,
  disabled,
  onChange,
}: ResizableProps) {
  const [values, setValues] = useState([localStart]);

  useEffect(() => {
    setValues([localStart]);
  }, [localStart]);

  const handleValues = (values: number[]) => {
    if (editable.disabled) return;

    setValues(values);
    onChange(values);
  };

  const handleChange = (newValues: number[]) => {
    handleValues(newValues);
  };

  const handleFinalChange = (finalValues: number[]) => {
    if (editable.disabled) return;
    if (finalValues[0] < start && finalValues[0] !== localStart) {
      const secs = getCurrentSeconds(
        dayjs(lowerLimit).format("MM/DD/YYYY HH:mm:ss")
      );

      return handleValues([secs + 60 * MINIMUM_STATUS_DURATION_IN_MINUTES]);
    }

    if (finalValues[0] > end && finalValues[0] !== localEnd) {
      return handleValues([end - 60 * MINIMUM_STATUS_DURATION_IN_MINUTES]);
    }

    handleValues(finalValues);
  };

  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <Range
        values={values}
        step={1}
        min={0}
        max={86400}
        onChange={handleChange}
        onFinalChange={handleFinalChange}
        renderTrack={({ props: railProps, children }) => (
          <Box
            {...railProps}
            style={{
              ...railProps.style,
              height: "6px",
              width: "100%",
              borderRadius: "4px",
              position: "absolute",
              bottom: 0,
            }}
          >
            {children}
          </Box>
        )}
        renderThumb={({ props: handleProps }) => {
          return (
            !disabled && (
              <SwipeIcon
                className="slider"
                {...handleProps}
                style={{
                  ...handleProps.style,
                }}
              />
            )
          );
        }}
      />
    </Box>
  );
}
