export const isStage =
  window.location.host.includes("stage") ||
  process.env.NODE_ENV === "development";

const prefix = isStage ? "stage." : "";

const https = `https://${prefix}api.mgkeld.com/api/`;
const socket = `wss://${prefix}api.mgkeld.com/ws/`;

const baseUrl = {
  https,
  socket,
};

export default baseUrl;
