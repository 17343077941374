import { grey } from "./colors";

const components = {
  MuiMenu: {
    styleOverrides: {
      paper: {
        boxShadow: "0px 8px 16px rgba(67, 66, 66, 0.15)",
        borderRadius: "0px 0px 8px 8px",
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        boxShadow: "0px 8px 16px rgba(67, 66, 66, 0.15)",
        borderRadius: "0px 0px 8px 8px",
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
        minHeight: 32,
        minWidth: 32,
        borderRadius: 8,
        textAlign: "center",
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    defaultProps: {
      variant: "outlined",
    },
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        "&.MuiFormLabel-root": {
          color: "red",
        },
        border: `${grey[100]} 1px solid`,
        overflow: "hidden",
        borderRadius: "8px",
        backgroundColor: grey[50],
        "&.Mui-focused": {
          backgroundColor: grey[50],
        },
        "&.MuiFilledInput-colorSecondary": {
          color: grey[50],
        },
        "&:hover": {
          backgroundColor: grey[50],
        },
      },
    },
  },
  // MuiButton: {
  //   styleOverrides: {
  //     root: {
  //       borderRadius: 8,
  //       padding: "11px 25px",
  //       fontSize: 14,
  //     },
  //   },
  // },
};

export default components;
