import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";

import { grey } from "../../theme/colors";
import { SidebarItemsType } from "../../types/sidebar";
import SidebarNavList from "./SidebarNavList";

const Title = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.caption.fontSize};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
  border: '1px solid green;
`;

type SidebarNavSectionProps = {
  className?: Element;
  component?: React.ElementType;
  pages: SidebarItemsType[];
  title?: string;
  divider: boolean;
};

const SidebarNavSection: React.FC<SidebarNavSectionProps> = (props) => {
  const {
    title,
    pages,
    className,
    component: Component = "nav",
    divider,
    ...rest
  } = props;
  return (
    <Component {...rest}>
      {title && <Title variant="subtitle2">{title}</Title>}
      <SidebarNavList pages={pages} depth={0} />
      {divider && <Divider sx={{ margin: "22px 6px" }} color={grey[600]} />}
    </Component>
  );
};

export default SidebarNavSection;
