import { locationRegex } from "../pages/Logs/components/utils";
import { number, object, string } from "yup";

export const englishStringRegEx = /^[A-Za-z0-9\s!@#$%^.,%?&*()_+-‘]+$/;

export const isRequired = " is a required field";

export const englishString = string().matches(
  englishStringRegEx,
  "English letters only"
);

export const loginValidationSchema = object().shape({
  username: string().max(255).required("Username is required"),
  password: string().max(255).required("Password is required"),
});

export const userValidationSchecma = object().shape({
  name: englishString.required("First Name" + isRequired),
  surname: englishString.required("Last Name" + isRequired),
  email: englishString.email().required("Email" + isRequired),
});

const octet = "(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)";
const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);
export const securityValidationSchema = object({
  name: string().required("Name is required"),
  address: string()
    .matches(regex, "Invalid IP address format")
    .required("Address is required"),
});

export function validateLocationInput(value: string | undefined) {
  if (!value) return false;
  const possibleCoords = value.split(",");
  const isValidCoords =
    possibleCoords.length === 2 &&
    possibleCoords.every((v) => !isNaN(Number(v)));
  if (isValidCoords) {
    return locationRegex.test(value); // Coordinate format
  } else if (englishStringRegEx.test(value)) {
    return true; // English letters and special symbols
  } else {
    return false; // Neither coordinate nor string format
  }
}

export const DotValidationSchecma = object().shape({
  driver_id: number().required("Driver" + isRequired),
  report_date: string().required("Date of Inspection" + isRequired),
  date_from: string().required("Logs affected from" + isRequired),
  date_to: string().required("Logs affected to" + isRequired),
  location: string()
    .test("location-or-english", "Invalid location", validateLocationInput)
    .required("Location is Required"),
});
