import { StatusMode } from "../../../../types/statusCard";
import { getSeconds } from "../utils";
import { Typography } from "@mui/material";

type LineType = { startDT: Date; endDT: Date; mode: StatusMode };

export const Line: React.FC<LineType> = (data: LineType) => {
  return (
    <Typography
      component="span"
      className={data.mode.split(" ").join("_").toLowerCase()}
      style={{
        left: `calc(0% + (100%/86400)*${getSeconds(data.startDT)})`,
        width: `calc(0% + (100%/86400)*${getSeconds(
          data.endDT
        )} - (0% + (100%/86400)*${getSeconds(data.startDT)}))`,
      }}
    ></Typography>
  );
};
