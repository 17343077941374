import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type Time = {
  id: number;
  time: string;
  isValid: boolean;
};
export interface InputState {
  drafts: Array<Time>;
}

const initialState: InputState = {
  drafts: [],
};

export const timeReduce = createSlice({
  name: "timeReduce",
  initialState,
  reducers: {
    setTimeReduce: (state, action: PayloadAction<Time>) => {
      const index = state.drafts.findIndex(
        (draft) => draft.id === action.payload.id
      );

      if (index >= 0) {
        state.drafts[index] = { ...state.drafts[index], ...action.payload };
      } else {
        state.drafts.push(action.payload);
      }
    },
    unsetDrafts: (state) => {
      state.drafts = [];
    },
  },
});

export const { setTimeReduce, unsetDrafts } = timeReduce.actions;

export default timeReduce.reducer;
