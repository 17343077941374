import async from "../components/Async";
import GlobalStyle from "../components/GlobalStyle";
import Sidebar from "../components/sidebar/Sidebar";
import dashboardItems from "../components/sidebar/dashboardItems";
import { Subjects } from "../contexts/AbilityContext";
import useAbility from "../hooks/useAbility";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiPaper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

const Navbar = async(() => import("../components/navbar/Navbar"));

const drawerWidth = 226;
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
`;
const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const ability = useAbility();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        window.smartlook||(function(d) {
          var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
          var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
          c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
          })(document);
          smartlook('init', 'b2900c6e1c3aeeb5322950056ef3670f24d0987b', { region: 'eu' });
      `;

      document.head.appendChild(script);
    }
  }, []);

  const items = useMemo(
    () =>
      dashboardItems.map((group) => ({
        ...group,
        pages: group.pages
          .filter((page) => ability.can("access", page.title as Subjects))
          .map((page) =>
            ability.can("click", page.title)
              ? page
              : { ...page, disabled: true }
          ),
      })),
    [ability]
  );

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={items}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={items}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent pt={isLgUp ? 2 : 2} px={isLgUp ? 6 : 4}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Layout;
