import { Row } from ".";
import { useAppSelector } from "../../../../hooks/reduxHooks";
import { setSelectedRect } from "../../../../redux/simple/slice";
import { RootState } from "../../../../redux/store";
import { formatAMPM, getDuration, getSeconds } from "../utils";
import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";

export type RectDataType = { id: number; startDT: Date; endDT: Date };
type RectType = RectDataType & {
  row: Row;
  disabled?: boolean;
  highlightedRowId: number;
  sethighlightedRowId: Dispatch<SetStateAction<number>>;
};

export type Coordinates = {
  leftX: number | null;
  rightX: number | null;
};

export const Rect: React.FC<RectType> = ({
  row,
  disabled,
  highlightedRowId,
  sethighlightedRowId,
  ...data
}) => {
  const dispatch = useDispatch();

  const isHighlighted = data.id === highlightedRowId;
  const selectedRect = useAppSelector(
    (state: RootState) => state.simple.selectedRect
  );

  const renderRect = () => {
    return (
      <Typography
        component="span"
        className={`rect ${
          selectedRect === data.id && !disabled ? "EditRect" : ""
        }`}
        onClick={() => !disabled && dispatch(setSelectedRect(data.id))}
        onMouseEnter={() => !disabled && sethighlightedRowId(data.id)}
        onMouseLeave={() => !disabled && sethighlightedRowId(-1)}
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: isHighlighted
            ? "rgba(167, 218, 230, .4588235294) !important"
            : "transparent",
          zIndex: isHighlighted ? "12 !important" : "10 !important",
          cursor: row.dot_sent ? "default !important" : "pointer",
        }}
      >
        <Typography component="span" className="logStart">
          {formatAMPM(data.startDT)}
        </Typography>
        <Typography component="span" className="logEnd">
          {formatAMPM(data.endDT)}
        </Typography>
        <Typography component="span" className="logDuration">
          {getDuration(data.startDT, data.endDT)}
        </Typography>
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        left: `calc(0% + (100%/86400)*${getSeconds(data.startDT)})`,
        width: `calc(0% + (100%/86400)*${getSeconds(
          data.endDT
        )} - (0% + (100%/86400)*${getSeconds(data.startDT)}))`,
        height: "100%",
        position: "absolute",
      }}
    >
      {renderRect()}
    </Box>
  );
};
