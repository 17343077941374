import baseUrl from "../constants/api";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: baseUrl.https,
});

export const REFRESH_TOKEN = "refreshToken";
export const ACCESS_TOKEN = "accessToken";

const isValidToken = (accessToken: string | null) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = ({
  refreshToken,
  accessToken,
}: {
  refreshToken?: string;
  accessToken?: string;
}) => {
  if (refreshToken && accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export const refreshTokenFn = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  try {
    const response = await axios.post(`${baseUrl.https}auth/refresh`, {
      refresh_token: refreshToken,
    });
    const { access_token, refresh_token } = response.data;
    setSession({ accessToken: access_token, refreshToken: refresh_token });
    return access_token;
  } catch (error) {
    setSession({}); // clean storage
    window.location.reload();
  }
};

let isRefreshing = false;
let failedQueue: { reject: any; resolve: any }[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log(error);

    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            // originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        refreshTokenFn()
          .then((token) => {
            axiosInstance.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            originalRequest.headers["Authorization"] = "Bearer " + token;
            processQueue(null, token);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    error?.response?.data?.errors?.map((err: string) => toast.error(err));
    return Promise.reject(error);
  }
);
export { isValidToken, setSession };
export default axiosInstance;
