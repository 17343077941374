import { useAppSelector } from "../hooks/reduxHooks";
import { getUserInfo } from "../redux/auth/selectors";
import { getCompanies } from "../redux/companies/selectors";
import { RouteNames } from "../routes";
import { UserRole } from "../types/auth";
import {
  CreateAbility,
  createMongoAbility,
  MongoAbility,
  AbilityBuilder,
} from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { createContext, ReactNode, useEffect, useState } from "react";

type Actions = "access" | "click";
export type Subjects = RouteNames | "all";

export type AppAbility = MongoAbility<[Actions, Subjects]>;
export const createAppAbility = createMongoAbility as CreateAbility<AppAbility>;

const AbilityContext = createContext<AppAbility>(null!);
const Can = createContextualCan(AbilityContext.Consumer);

const defineRulesFor = (role: UserRole) => {
  const { can, cannot, build } = new AbilityBuilder(createAppAbility);
  switch (role) {
    case "EMD admin":
      can("access", "all");
      break;
    case "EMD manager":
      can("access", "all");
      cannot("access", "Users");
      cannot("access", "UsersForm");
      break;
    default:
      break;
  }
  return build();
};

function AbilityProvider({ children }: { children: ReactNode }) {
  const userInfo = useAppSelector(getUserInfo);
  const companiesList = useAppSelector(getCompanies);
  const [ability, setAbility] = useState<AppAbility>(
    defineRulesFor("unauthorized")
  );
  useEffect(() => {
    if (!userInfo) return setAbility(defineRulesFor("unauthorized"));
    setAbility(defineRulesFor(userInfo.role_name));
  }, [userInfo, companiesList]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}

export { AbilityContext, AbilityProvider, Can };
