import { Row } from ".";
import { statuses } from "../../../../constants";
import { DraftState } from "../../../../redux/draft/slice";
import { StatusMode } from "../../../../types/statusCard";
import { ExtendedStatusMode, extendedModes } from "../LogEvents";
import dayjs from "dayjs";
import { mapValues } from "lodash";

export const isInStatusMode = (x: any): x is StatusMode =>
  [extendedModes[0], ...statuses].includes(x);

export const getStatusMode = (status: ExtendedStatusMode): StatusMode => {
  if (status && status === extendedModes[0]) {
    return "DRIVING";
  }

  return status as StatusMode;
};

export const getDTs = (defaultDT: Date, draftDT?: string) => {
  return draftDT ? new Date(draftDT) : defaultDT;
};

export const getCurrentSeconds = (date: string) => {
  return (
    (dayjs(date).hour() * 60 + dayjs(date).minute()) * 60 + dayjs(date).second()
  );
};

export const getDateFromSeconds = (sec: number, date?: string) => {
  return dayjs(dayjs(date).format("YYYY-MM-DD")).add(sec, "seconds");
};

export const getBoundsUtil = (
  rows: Row[],
  drafts: DraftState,
  date: string,
  id: number
) => {
  const index = rows.findIndex((row) => row.id === id);

  if (index < 0) return;

  const currentDate = dayjs().tz();
  const isCurrent =
    dayjs(rows[index].start).isSame(currentDate, "date") &&
    index + 1 === rows.length;

  const prevDraft = drafts.drafts.find(
    (draft) => draft.id === rows[index - 1]?.id
  );
  const currentDraft = drafts.drafts.find(
    (draft) => draft.id === rows[index]?.id
  );
  const nextDraft = drafts.drafts.find(
    (draft) => draft.id === rows[index + 1]?.id
  );

  let start = dayjs(date).startOf("day").format("MM/DD/YYYY HH:mm:ss");
  let end = dayjs(date).endOf("day").format("MM/DD/YYYY HH:mm:ss");
  let localStart = dayjs(rows[index].start).format("MM/DD/YYYY HH:mm:ss");
  let localEnd = dayjs(rows[index].end).format("MM/DD/YYYY HH:mm:ss");

  if (currentDraft) {
    localStart = dayjs(currentDraft.start).format("MM/DD/YYYY HH:mm:ss");
    localEnd = dayjs(currentDraft.end).format("MM/DD/YYYY HH:mm:ss");
  }

  if (!currentDraft && dayjs(date).isAfter(localStart, "day")) {
    localStart = start;
  }

  if (!currentDraft && dayjs(localEnd).isAfter(date, "day")) {
    localEnd = end;
  }

  if (prevDraft) {
    start = prevDraft?.end || start;
  } else if (rows[index - 1]) {
    start = dayjs(rows[index - 1].end).format("MM/DD/YYYY HH:mm:ss");
  }

  if (nextDraft) {
    end = nextDraft?.start || end;
  } else if (
    rows[index + 1] &&
    dayjs(rows[index + 1].start).isSame(date, "days")
  ) {
    end = dayjs(rows[index + 1].start).format("MM/DD/YYYY HH:mm:ss");
  }

  if (isCurrent) {
    end = localEnd;
  }

  const min = getCurrentSeconds(start);
  const max = getCurrentSeconds(end);
  const localMin = getCurrentSeconds(localStart);
  const localMax = getCurrentSeconds(localEnd);

  return {
    start: min,
    end: max,
    localEnd: localMax,
    localStart: localMin,
  };
};

export const getLimit = (
  activityRows: Row[],
  date: string,
  selectedRect: number,
  prevOrNext: -1 | 1
) => {
  const rowIndex = activityRows.findIndex((row) => row.id === selectedRect);

  return activityRows[rowIndex + prevOrNext] &&
    dayjs(activityRows[rowIndex + prevOrNext].start).isSame(date, "date")
    ? activityRows[rowIndex + prevOrNext].start
    : prevOrNext === -1
    ? dayjs(date).startOf("date")
    : dayjs(date).endOf("date");
};

export type Editable = {
  disabled: boolean;
  status: boolean;
  start: boolean;
  end: boolean;
  location: boolean;
  vehicle: boolean;
  odometer: boolean;
  engHour: boolean;
  notes: boolean;
  doc: boolean;
};

const initialValue = {
  disabled: false,
  status: true,
  start: true,
  end: true,
  location: true,
  vehicle: true,
  odometer: true,
  engHour: true,
  notes: true,
  doc: true,
};

export const isEditable = (
  rows: Row[],
  date: string,
  id: number,
  endDate?: string
) => {
  const index = rows.findIndex((row) => row.id === id);
  if (index < 0) return;
  const currentDate = dayjs().tz();

  const isPast = dayjs(rows[index].start).isBefore(date, "date");
  const isFuture = dayjs(rows[index].start).isAfter(endDate || date, "date");
  const status = rows[index].status;
  const isCurrent =
    (dayjs(rows[index].start).isSame(endDate || date, "date") ||
      dayjs(rows[index].start).isBefore(currentDate, "date")) &&
    index + 1 === rows.length;

  let editing: Editable = { ...initialValue };

  // future and past logs are not editable (future and past according to the selected date)
  if (isFuture || isPast) {
    editing.disabled = true;
  }

  // disable editing if the current status is driving
  if (isCurrent && status === statuses[0]) {
    editing = mapValues(editing, () => false);
    editing.start = true;
  }

  if (status === extendedModes[3]) {
    editing.engHour = false;
    editing.odometer = false;
    editing.location = false;
  } else if (status === extendedModes[4]) {
    editing.location = false;
  }

  return editing;
};
