import { RootState } from "./../store";
import { createSelector } from "@reduxjs/toolkit";

export const getAuthState = (state: RootState) => state.auth;

export const getIsAuthenticated = createSelector(
  [getAuthState],
  (state) => state.isAuthenticated
);
export const getIsInitialized = createSelector(
  [getAuthState],
  (state) => state.isInitialized
);

export const getUserInfo = createSelector(
  [getAuthState],
  (state) => state.user
);
