import { driverStatusDefinitions } from "../constants";
import { green } from "../theme/colors";
import { StatusMode, StatusType } from "../types/statusCard";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import qs from "qs";

export const getFormatedDate = (date: string) => {
  const dateObj = dayjs(date).utcOffset(date.slice(-6));

  if (!dateObj.isValid()) return date;
  return `${dateObj.format("DD MMM, YYYY")} at ${dateObj.format("HH:mm")}`;
};

export const getStatus = (value: StatusMode): StatusType => {
  return (
    Object.values(driverStatusDefinitions).find(
      (status: StatusType) => status.mode === value
    ) || { mode: "DRIVING", color: green }
  );
};

export const exportExcel = async (
  url: string,
  params?: any,
  filename = "file.xslx",
  headers = {}
) => {
  const query = params && qs.stringify(params, { arrayFormat: "repeat" });
  try {
    const response = await fetch(`${url}?${query}`, { headers: headers });

    if (!response.ok) {
      throw new Error();
    }
    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);
  } catch (error) {
    console.error("Failed to download file", error);
    throw new Error();
  }
};

export const englishLettersRegex = /^[A-Za-z0-9\s!@#$%^.,%?&*()_+-]+$/;
