import authReducer from "./auth/slice";
import companiesReducer from "./companies/slice";
import draftReducer from "./draft/slice";
import inputReducer from "./simple/slice";
import timeReducer from "./timeReduce/slice";
import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    auth: authReducer,
    drafts: draftReducer,
    simple: inputReducer,
    timeReduce: timeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = Promise<any>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
