import baseUrl from "../constants/api";
import axiosInstance from "../utils/jwt";

const login = (username: string, password: string) =>
  axiosInstance.post(
    `${baseUrl.https}auth/login/emd`,
    { username, password },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

const authApi = {
  login,
};

export default authApi;
