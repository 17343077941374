import { NewLog } from "../../../services/logs";
import { LogDetail } from "../../../types/helpers";
import { LogDetailRows } from "../Info";
import { extendedModes } from "./LogEvents";
import { Errors, PropsToCheck } from "./types";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

export const dateFormat = "YYYY-MM-DDTHH:mm:ss";
export const locationRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const getSeconds = (date: Date) =>
  (date.getHours() * 60 + date.getMinutes()) * 60 + date.getSeconds();
export const formatZeros = (time: number) => ("0" + time).slice(-2);
export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  return (
    hours +
    ":" +
    formatZeros(minutes) +
    ":" +
    formatZeros(date.getSeconds()) +
    " " +
    ampm
  );
};

export const getDuration = (startDT: Date, endDT: Date) => {
  const differenceInMS = Math.abs(startDT.getTime() - endDT.getTime());
  const date = new Date(0, 0, 0, 0, 0, 0, differenceInMS);
  return `${formatZeros(date.getHours())}h:${formatZeros(
    date.getMinutes()
  )}m:${formatZeros(date.getSeconds())}s`;
};

// used to convert seconds to hhmmss format, it will return from 00:00:00 to 24:00:00
export const toHHMMss = (seconds: number = 0) => {
  return seconds === 86400
    ? "24:00"
    : new Date(seconds * 1000).toISOString().slice(11, 19);
};

// this one is used for duration, where duration can be more than 24 hrs. it can return ex: 34:15:12
export const toHHMMSS = (secs: number) => {
  if (secs === null) {
    return "N/A";
  }
  const secNum = parseInt(String(secs), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds].map((v) => (v < 10 ? "0" + v : v)).join(":");
};

export const toHHMM = (mins: number) => {
  if (mins === null) {
    return "N/A";
  }
  const secNum = parseInt(String(mins), 10) * 60;
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;

  return [hours, minutes].map((v) => (v < 10 ? "0" + v : v)).join(":");
};

export const toMMSS = (sec: number) => {
  const minutes = Math.floor(sec / 60);
  const seconds = sec % 60;
  return `${formatZeros(minutes)}:${formatZeros(seconds)}`;
};

export const hhMMSS2Seconds = (hhmmss: string) => {
  const [hh, mm, ss] = hhmmss.split(":");
  return (Number(hh) * 60 + Number(mm)) * 60 + (Number(ss) || 0);
};

const isNullOrEmpty = (value?: string | number) => {
  return value === undefined || value === "" || value === null;
};

export const checkIntermediateFreq = (
  logDetail: Partial<LogDetail>,
  errors: Errors,
  prevLog?: PropsToCheck
) => {
  if (logDetail.status === "INTERMEDIATE" && prevLog) {
    if (prevLog.status === "DRIVING" || prevLog.status === "INTERMEDIATE") {
      if (
        dayjs(logDetail.datetime).diff(prevLog.datetime, "seconds") !== 3600
      ) {
        errors.intermediate_freq = true;
      }
    }
  }
};

const checkForEmpty = (logDetail: Partial<LogDetail>, errors: Errors) => {
  if (!extendedModes.slice(3).includes(logDetail.status || "")) {
    if (
      isNullOrEmpty(logDetail.odometer_value) ||
      isNullOrEmpty(logDetail.engine_hours) ||
      isNullOrEmpty(logDetail.location) ||
      isNullOrEmpty(logDetail.location_latitude) ||
      isNullOrEmpty(logDetail.location_longitude)
    ) {
      errors.empty_check = true;
    }
  }
};

const checkEngineOdometer = (
  logDetail: PropsToCheck,
  errors: Errors,
  prevLog?: PropsToCheck
) => {
  const cert = extendedModes[5];
  const { engine_hours, odometer_value, status } = logDetail;

  // ation status login logout statuses
  if (status === extendedModes[3] || status === extendedModes[4]) {
    return;
  }

  //exclude certification status
  if (prevLog && status !== cert && prevLog.status !== cert) {
    if (engine_hours === null || engine_hours < prevLog.engine_hours) {
      errors.engine_hours = true;
    }
    if (odometer_value === null || odometer_value < prevLog.odometer_value) {
      errors.odometer_check = true;
    }
  }
};

const checkOdometerLocationMatch = (
  logDetail: PropsToCheck,
  movingStatuses: PropsToCheck[],
  errors: Errors
) => {
  const {
    id,
    location,
    status,
    odometer_value,
    location_latitude,
    location_longitude,
  } = logDetail;
  const currentMovingLog = movingStatuses.findIndex((log) => log.id === id);
  const prevMovingLog = movingStatuses[currentMovingLog - 1];

  // ation status login logout statuses
  if (status === extendedModes[3] || status === extendedModes[4]) {
    return;
  }

  if (
    prevMovingLog &&
    (prevMovingLog.status === "ON_DUTY" ||
      prevMovingLog.status === "OFF_DUTY" ||
      prevMovingLog.status === "SLEEPER")
  ) {
    if (prevMovingLog.odometer_value !== odometer_value) {
      errors.odometer_match = true;
    }

    if (!locationRegex.test(logDetail.location)) {
      if (prevMovingLog.location !== location) {
        errors.location_match = true;
      }
    } else if (
      prevMovingLog.location_latitude !== location_latitude ||
      prevMovingLog.location_longitude !== location_longitude
    ) {
      errors.location_match = true;
    }
  }
};

const checkLocation = (
  allMovingStatuses: PropsToCheck[],
  id: number,
  errors: Errors
) => {
  const cMovingLogIndex = allMovingStatuses.findIndex((log) => log.id === id);
  const cMovingLog = allMovingStatuses[cMovingLogIndex];
  const pMovingLog = allMovingStatuses[cMovingLogIndex - 1];

  if (
    dayjs(cMovingLog?.datetime).diff(pMovingLog?.datetime, "seconds") >= 300
  ) {
    if (
      pMovingLog.location_latitude === cMovingLog.location_latitude &&
      pMovingLog.location_longitude === cMovingLog.location_longitude
    ) {
      if (!["ON_DUTY", "OFF_DUTY", "SLEEPER"].includes(pMovingLog.status))
        errors.location_check = true;
    }
  }
};

const isSameVehicle = (logDetail: PropsToCheck, prevLog?: PropsToCheck) => {
  return prevLog && logDetail.vehicle_id === prevLog.vehicle_id;
};

const checkLogin = (
  logInLogOuts: PropsToCheck[],
  id: number,
  errors: Errors
) => {
  const currentLoginIndex = logInLogOuts.findIndex((log) => log.id === id);
  const prevLog = logInLogOuts[currentLoginIndex - 1];
  const curLog = logInLogOuts[currentLoginIndex];
  const nextLog = logInLogOuts[currentLoginIndex + 1];
  if (nextLog?.status === curLog.status || prevLog?.status === curLog.status) {
    errors.consequent_logins = true;
  }
};

export const checkErrors = (
  logDetail: PropsToCheck,
  allMovingStatuses: PropsToCheck[],
  movingStatuses: PropsToCheck[],
  logInLogOuts: PropsToCheck[],
  prevLog?: PropsToCheck
) => {
  const errors: Errors = {};

  if (logDetail.status === "LOGIN") {
    checkLogin(logInLogOuts, logDetail.id, errors);
  }

  checkIntermediateFreq(logDetail, errors, prevLog);
  checkForEmpty(logDetail, errors);

  // check if the log is in the same vehicle
  if (isSameVehicle(logDetail, prevLog)) {
    checkEngineOdometer(logDetail, errors, prevLog);
    checkOdometerLocationMatch(logDetail, movingStatuses, errors);
    checkLocation(allMovingStatuses, logDetail.id, errors);
  }

  if (!isEmpty(errors)) {
    errors.isValid = false;
  } else {
    errors.isValid = true;
  }

  return errors;
};

export const sortLogs = (rows: LogDetailRows) => {
  return [...rows].sort((a, b) => {
    const d1 = dayjs(a.start).unix();
    const d2 = dayjs(b.start).unix();

    return d1 - d2;
  });
};

export const getErrors = (rows: LogDetailRows, id: number) => {
  const index = rows.findIndex((row) => row.id === id);
  const mappedRows = rows.map((r) => {
    let lat = r.location_latitude;
    let long = r.location_longitude;

    if (locationRegex.test(r.location)) {
      const locations = r.location.replace(/[\s]/g, "").split(",");
      lat = parseFloat(locations[0]);
      long = parseFloat(locations[1]);
    }

    return {
      ...r,
      odometer_value: r.odometer,
      engine_hours: r.engHour,
      location_latitude: lat,
      location_longitude: long,
    };
  });

  const prevLogPropsTocheck = mappedRows[index - 1]
    ? logDetailsToPropsToCheck(mappedRows[index - 1] as any)
    : undefined;
  const curLogPropsTocheck = logDetailsToPropsToCheck(mappedRows[index] as any);
  const moving = mappedRows.filter((r) => r.type === "ACTIVITY");
  const logInLogOuts = mappedRows
    .filter((r) => r.status === "LOGIN" || r.status === "LOGOUT")
    .map((r) => logDetailsToPropsToCheck(r as any));

  const allMovingStatuses = moving.map((r) =>
    logDetailsToPropsToCheck(r as any)
  );
  const movingWithoutIntermediate = allMovingStatuses.filter(
    (log) => log.status !== "INTERMEDIATE"
  );

  return checkErrors(
    curLogPropsTocheck,
    allMovingStatuses,
    movingWithoutIntermediate,
    logInLogOuts,
    prevLogPropsTocheck
  );
};

export const logDetailsToPropsToCheck = (logDetail: LogDetail) => {
  return {
    id: logDetail.id,
    status: logDetail.status,
    datetime: logDetail.datetime,
    vehicle_id: logDetail.vehicle_id,
    duration: logDetail.duration,
    odometer_value: logDetail.odometer_value,
    engine_hours: logDetail.engine_hours,
    location: logDetail.location,
    location_latitude: logDetail.location_latitude,
    location_longitude: logDetail.location_longitude,
  };
};

export const mapLogDetails2Row = (logDetails: LogDetail[]) => {
  const allMovingStatuses = logDetails.filter((log) => log.type === "ACTIVITY");
  const movingWithoutIntermediate = allMovingStatuses.filter(
    (log) => log.status !== "INTERMEDIATE"
  );
  const logInLogOuts = logDetails
    .filter((r) => r.status === "LOGIN" || r.status === "LOGOUT")
    .map((r) => logDetailsToPropsToCheck(r as any));

  return logDetails.map((logDetail, index) => {
    return {
      ...logDetail,
      id: Date.now() + index,
      originalId: logDetail.id,
      start: dayjs(logDetail.datetime).format("MM/DD/YYYY hh:mm:ss A"),
      end: logDetail.datetime_end
        ? dayjs(logDetail.datetime_end).format("MM/DD/YYYY hh:mm:ss A")
        : undefined,
      duration: toHHMMSS(logDetail.duration),
      vehicle: logDetail.vehicle_number,
      odometer: logDetail.odometer_value,
      engHour: logDetail.engine_hours,
      doc: logDetail.shipping_documents,
      isNew: false,
      editing: false,
      waypoints: logDetail.waypoints,
      errors: checkErrors(
        logDetail,
        allMovingStatuses,
        movingWithoutIntermediate,
        logInLogOuts,
        logDetails[index - 1]
          ? logDetailsToPropsToCheck(logDetails[index - 1])
          : undefined
      ),
    };
  });
};

const splitLatLng = (location: string, log?: LogDetail) => {
  if (locationRegex.test(location)) {
    const splittedLocation = location.replaceAll(" ", "").split(",");
    return {
      location_latitude: parseFloat(splittedLocation[0]),
      location_longitude: parseFloat(splittedLocation[1]),
    };
  } else {
    return {
      location_latitude: log?.location_latitude,
      location_longitude: log?.location_longitude,
    };
  }
};

const getDate = (date: string) => {
  return (
    dayjs(date).tz(undefined, true).isAfter(dayjs().tz())
      ? dayjs().tz()
      : dayjs(date)
  ).format(dateFormat);
};

export const mapLogs = (allLogs: LogDetailRows, logsFromDB: LogDetail[]) => {
  const newLogs: LogDetailRows = [];
  const initialLogs: LogDetailRows = [];
  allLogs.forEach((log) => {
    if (log.has_been_added) {
      newLogs.push(log);
    } else {
      initialLogs.push(log);
    }
  });

  const finalLogs = initialLogs.map((log) => {
    const logFromDB = logsFromDB.find((l) => l.id === log.originalId);

    const latLng = splitLatLng(log.location, logFromDB);

    if (log.has_been_updated) {
      let datetime = getDate(log.start);
      const isCertification = log.status === "CERTIFICATION";

      return {
        ...logFromDB,
        has_been_updated: log.has_been_updated,
        has_been_deleted: log.has_been_deleted,
        status: log.status,
        status_activation_time: datetime,
        datetime: datetime,
        datetime_end: dayjs(log.end).format(dateFormat),
        location_latitude: isCertification
          ? undefined
          : latLng.location_latitude,
        location_longitude: isCertification
          ? undefined
          : latLng.location_longitude,
        odometer_value: isCertification ? undefined : log.odometer || 0,
        engine_hours: isCertification ? undefined : log.engHour || 0,
        notes: log.notes,
        shipping_documents: log.doc,
        client_id: 0,
        type: log.type,
        status_note: log.status_note,
        vehicle_id: log.vehicle_id,
        vehicle_number: log.vehicle_number,
        vehicle_vin: log.vehicle_vin,
        waypoints: log.waypoints,
      };
    }

    if (log.has_been_deleted) {
      return {
        ...logFromDB,
        has_been_deleted: log.has_been_deleted,
        has_been_updated: log.has_been_updated,
        status: log.status,
        client_id: 0,
      };
    }

    return {
      ...logFromDB,
      has_been_deleted: false,
      has_been_updated: false,
      status: log.status,
      client_id: 0,
    };
  });

  const formattedNewLogs: NewLog[] = newLogs.map((log) => {
    const firstLogToCopy = logsFromDB[0];
    const latLng = splitLatLng(log.location, firstLogToCopy);

    if (log.status === "CERTIFICATION") {
      let datetime = getDate(log.start);

      return {
        status: log.status,
        origin: "MANUAL",
        has_been_added: log.has_been_added,
        has_been_updated: log.has_been_updated,
        datetime: datetime,
        status_activation_time: datetime,
        type: log.type,
        status_note: log.status_note,
        vehicle_id: log.vehicle_id,
        vehicle_number: log.vehicle_number,
      };
    }

    const exclude = log.status === "LOGIN" || log.status === "LOGOUT";

    return {
      status: log.status,
      origin: log.status === "DRIVING" ? "AUTO" : "MANUAL",
      sequence_number_id: null,
      has_been_added: log.has_been_added,
      has_been_updated: log.has_been_updated,
      status_activation_time: dayjs(log.start).format(dateFormat),
      datetime: dayjs(log.start).format(dateFormat),
      datetime_end: dayjs(log.end).format(dateFormat),
      location: exclude ? undefined : log.location,
      odometer_value: log.odometer,
      engine_hours: log.engHour,
      notes: log.notes,
      shipping_documents: log.doc,
      status_note: log.status_note,
      type: log.type,
      client_id: firstLogToCopy.client_id,
      eld_mac: firstLogToCopy.eld_mac,
      eld_reg_id: firstLogToCopy.eld_reg_id,
      eld_type: firstLogToCopy.eld_type,
      location_latitude: exclude ? undefined : latLng.location_latitude,
      location_longitude: exclude ? undefined : latLng.location_longitude,
      vehicle_id: log.vehicle_id,
      vehicle_number: log.vehicle_number,
      vehicle_vin: log.vehicle_vin,
      speed: firstLogToCopy.speed,
      waypoints: log.waypoints,
    };
  });

  return { finalLogs, formattedNewLogs };
};
