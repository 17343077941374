import { StatusMode } from "../../../../types/statusCard";
import { getSeconds } from "../utils";
import { Typography } from "@mui/material";


type VertucalType = { at: Date; currentMode: StatusMode; nextMode: StatusMode };
const getOption = (currentMode: StatusMode, nextMode: StatusMode) => {
  currentMode = currentMode === "PERSONAL_USE" ? "OFF_DUTY" : currentMode;
  currentMode = currentMode === "YARD_MOVE" ? "ON_DUTY" : currentMode;
  nextMode = nextMode === "PERSONAL_USE" ? "OFF_DUTY" : nextMode;
  nextMode = nextMode === "YARD_MOVE" ? "ON_DUTY" : nextMode;
  return `${currentMode}-${nextMode}`;
};

const getTop = (currentMode: StatusMode, nextMode: StatusMode) => {
  switch (getOption(currentMode, nextMode)) {
    case "ON_DUTY-OFF_DUTY":
    case "DRIVING-OFF_DUTY":
    case "SLEEPER-OFF_DUTY":
    case "OFF_DUTY-ON_DUTY":
    case "OFF_DUTY-DRIVING":
    case "OFF_DUTY-SLEEPER":
      return "12.7%";
    case "ON_DUTY-SLEEPER":
    case "DRIVING-SLEEPER":
    case "SLEEPER-ON_DUTY":
    case "SLEEPER-DRIVING":
      return "37.7%";
    case "ON_DUTY-DRIVING":
    case "DRIVING-ON_DUTY":
      return "62.7%";
  }
};

const getHeight = (currentMode: StatusMode, nextMode: StatusMode) => {
  switch (getOption(currentMode, nextMode)) {
    case "ON_DUTY-DRIVING":
    case "DRIVING-SLEEPER":
    case "SLEEPER-OFF_DUTY":
    case "OFF_DUTY-SLEEPER":
    case "SLEEPER-DRIVING":
    case "DRIVING-ON_DUTY":
      return "27%";
    case "ON_DUTY-SLEEPER":
    case "DRIVING-OFF_DUTY":
    case "OFF_DUTY-DRIVING":
    case "SLEEPER-ON_DUTY":
      return "52%";
    case "ON_DUTY-OFF_DUTY":
    case "OFF_DUTY-ON_DUTY":
      return "77%";
    default:
      return "0%";
  }
};

export const Vertical: React.FC<VertucalType> = ({
  at,
  currentMode,
  nextMode,
}) => {
  const top = getTop(currentMode, nextMode);
  return (
    <Typography
      component="span"
      className="vertical"
      style={{
        left: `calc(0% + (100%/86400)*${getSeconds(at)})`,
        top: top,
        height: getHeight(currentMode, nextMode),
      }}
    ></Typography>
  );
};