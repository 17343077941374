import { useAppSelector } from "../../hooks/reduxHooks";
import useAbility from "../../hooks/useAbility";
import {
  getIsAuthenticated,
  getIsInitialized,
} from "../../redux/auth/selectors";
import routes from "../../routes";
import React, { useEffect, useMemo } from "react";
import {
  matchRoutes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const ability = useAbility();
  const routeName = useMemo(() => {
    const shortlist = matchRoutes(routes, location);
    const el = shortlist?.find((r) => !r.route.children);
    //@ts-expect-error err
    return el?.route.name;
  }, [location]);

  useEffect(() => {
    if (ability.rules.length === 0) return;
    if (ability.cannot("access", routeName)) navigate("/logs");
  }, [ability, navigate, routeName]);

  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const isInitialized = useAppSelector(getIsInitialized);
  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
